export class Plan {
	Id: number;
	Name: string;
	Price: number;
	Items: string[];
	Subscribed: boolean;

	constructor (
		Id: number, Name: string, Price: number, Items: string[],
		Subscribed: boolean = false
	) {
		this.Id = Id;
		this.Name = Name;
		this.Price = Price;
		this.Items = Items;
		this.Subscribed = Subscribed;
	}
}
export interface Plans {
	id: number | any;
	name: string;
	periodicity: any
	periodicity_type: any;
	price: number;
	currency: string
	grace_days: number
	features: Feature[],
	subscribed: boolean
  }
  
  export interface Feature {
	name: string
	consumable: boolean
	charges?: string | null,
	status:boolean
	description:string,
	title:string|null

  }


  export enum FeatureName {
	CREATE_PUBLIC_PROFILE = "CREATE PUBLIC PROFILE",
	DEDICATED_PROFILE_URL = "DEDICATED PROFILE URL",
	IMAGES = "IMAGES",
	VIDEOS = "VIDEOS",
	ADD_NEWS = "ADD NEWS",
	SPECIAL_CARD = "SPECIAL CARD",
	PRIORITIZE_PROFILE_ON_SEARCH_RESULTS = "PRIORITIZE PROFILE ON SEARCH RESULTS",
	ADD_SPORTS_PLAYGROUNDS = "ADD SPORTS PLAYGROUNDS",
	SPORTS_PROGRAM = "SPORTS PROGRAM"
  }